import { useStaticQuery, graphql } from 'gatsby';

// eslint-disable-next-line import/prefer-default-export
export const getRestaurantPage = () =>
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useStaticQuery(graphql`
    query {
      allWordpressPage(filter: { id: { eq: "547ac532-1984-5e34-b7bd-0c06052a8d36" } }) {
        edges {
          node {
            acf {
              restaurantHeroHeading
              restaurantHeroImages {
                image {
                  localFile {
                    childImageSharp {
                      fluid(maxWidth: 900) {
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }
                }
              }
              restaurantSoups {
                category
                products {
                  calories
                  description
                  name
                  price
                  quantity
                  unit
                }
              }
              restaurantMain {
                category
                products {
                  calories
                  description
                  name
                  price
                  quantity
                  unit
                }
              }
              restaurantPizza {
                category
                products {
                  calories
                  description
                  name
                  price
                  quantity
                  unit
                }
              }
              restaurantEntries {
                category
                products {
                  calories
                  description
                  name
                  price
                  quantity
                  unit
                }
              }
              restaurantDesserts {
                category
                products {
                  calories
                  description
                  name
                  price
                  quantity
                  unit
                }
              }
              restaurantBeverages {
                category
                products {
                  calories
                  description
                  name
                  price
                  quantity
                  unit
                }
              }
              restaurantWines {
                category
                products {
                  calories
                  description
                  name
                  price
                  quantity
                  unit
                }
              }
              restaurantNawynos {
                category
                products {
                  calories
                  description
                  name
                  price
                  quantity
                  unit
                }
              }
            }
          }
        }
      }
    }
  `);
