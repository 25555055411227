import React, { useState } from 'react';

import Hero from '../../components/Hero/Hero';
import Tabs from '../../components/Tabs/Tabs';

import { getRestaurantPage } from './restaurant.query';

const Restaurant = () => {
  const data = getRestaurantPage();

  const [currentTab, setCurrentTab] = useState('Zupy');

  const onTabChange = tab => {
    setCurrentTab(tab);
  };

  const {
    restaurantHeroHeading,
    restaurantHeroImages,
    restaurantSoups,
    restaurantMain, // those is actually entries due to a mistake when entering data
    restaurantDesserts,
    restaurantEntries, // those is actually mains
    restaurantBeverages,
    restaurantPizza,
    restaurantWines,
    // restaurantSeafood,
    restaurantNawynos,
  } = data.allWordpressPage.edges[0].node.acf;

  const menuItems = {
    Przystawki: restaurantMain,
    Zupy: restaurantSoups,
    'Dania główne': restaurantEntries,
    Pizza: restaurantPizza,
    Desery: restaurantDesserts,
    Napoje: restaurantBeverages,
    // 'Owoce morza': restaurantSeafood,
    'Na wynos': restaurantNawynos,
    Wina: restaurantWines,
  };

  return (
    <>
      <Hero heading={restaurantHeroHeading} slides={restaurantHeroImages} />
      <Tabs
        currentTab={currentTab}
        handleTabChange={onTabChange}
        items={menuItems[currentTab]}
        categories={Object.keys(menuItems)}
      />
    </>
  );
};

export default Restaurant;
